import React from 'react';

import LocationMenuItem from './LocationMenuItem.js';



export class LocationMenuSection extends React.Component { 


    render() { 

        let obj     = this.props.data; 
        let name    = obj.name; 
        let desc    = obj.description; 
        let list    = obj.menu_item_list;

        let ref     = this.props.scrollRef; 
        let key     = this.props.key; 

        let parent  = this.props.parent; 


        let p = Object.assign({}, this.props); 
        delete p.parentBindings;  // the ref interferes with the thing

        return (
          <section className="LocationMenuSection" ref={ref} {... p} >
            <h1>{ name }</h1>

            { list.map(x => (<LocationMenuItem data={x} parent={parent} key={x.item_id} />)) }
          </section>
        );

    }

}

export class MenuSectionButton extends React.Component { 

  render() { 
    let x = this.props.data; 

    return (<button {... this.props}  >{ x.name }</button>)
  }

  // onClick = () => { 
  //   let ref = this.props.scrollRef; 
  //   ref.current.scrollIntoView({  behavior: 'smooth' });
  // }

}