import React from 'react'; 

import { Link } from 'react-router-dom';

export default class LocationMenuItem extends React.Component { 

    render() { 
        let obj     = this.props.data; 
        let id      = obj.item_id; 
        let name    = obj.name;
        let price   = obj.price; 
        let desc    = obj.description;

        let parent  = this.props.parent; 

        return (
          <Link   
                to={ x => ({ 
                    pathname: x.pathname + '-item/' + id, 
                    state: { item: obj, menu: parent } 
                }) }
                
                className='LocationMenuItem'
          >

            {/* <div className="image"></div> */}
            <img src={obj.imageurl} />

            <h2>{ name }</h2>
            <p className="desc">{ desc }</p>
            <p className="price">Starting at <strong>{ price }</strong></p>

          </Link>
        )
    }

}