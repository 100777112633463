import React from "react"
import PropTypes from "prop-types"

import {BrowserRouter, Switch, Route} from 'react-router-dom'

import LocationMenu from './LocationMenu/LocationMenu.js';
import MenuItemView from "./MenuItemView/MenuItemView.js";


class App extends React.Component {

  constructor(props) { 
    super(props); 
    this.navRef = React.createRef(); 
  }

  render () {

    return (

      <React.Fragment>

        <nav id="ULinkNav" ref={this.navRef}>
          <h1>ULink</h1>
        </nav>

        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => {"Home!"}} />
            
            {/* Passing components - https://ui.dev/react-router-v4-pass-props-to-components/ */}
            <Route path="/locations/:location_id/menu" render={x => (<LocationMenu {...x} navRef={this.navRef} />)} />
            <Route path="/locations/:location_id/menu-item/:item_id" component={MenuItemView} />

          </Switch>
        </BrowserRouter>



      </React.Fragment>

    );
  }
}

export default App
