import React from 'react'; 

export default class ModifierList extends React.Component { 

    render() { 

        let list = this.props.data; 
        // console.log(list[0]); 

        return <div className="ModifierList">
            { list.map(x => <Modifier data={x} key={x.modifier_id} /> ) }
        </div>;

    }

}




class Modifier extends React.Component { 

    render() { 
        let obj = this.props.data; 
        let options = obj.modifier_item_list; 

        return <div className="Modifier">
            <h3>{ obj.name }</h3>

            <div role="group">
            { options.map(x => <ModifierItem data={x} key={x.item_id} />) }
            </div>
        </div>
    }

}


class ModifierItem extends React.Component { 

    render() { 
        let obj = this.props.data; 

        return <div className="ModifierItem">{ obj.name }</div>
    }

}