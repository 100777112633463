import React from 'react'; 

import { useParams } from 'react-router-dom'; 

import { ULINK_API } from 'ulink_global'; 

import ModifierList from './ModifierList'; 


export default class MenuItemView extends React.Component { 

    state = { 
        menu:   null, 
        item:   null, 
    };

    constructor(props) { 
        super(props); 
    }

    componentDidMount() { 

        let passedState = this.props.location.state; 
        // console.log('passed:');
        // console.log(passedState);

        if(passedState) { 
            this.setState({ 
                menu:   passedState.menu, 
                item:   passedState.item, 
            })
        }
        else { 
            //TODO: Implement fetch here
            // Doing this for now just for convenience sake 
            console.log('MenuItemView:  No state passed, loading test data instead...');

            let testdata = ULINK_API.TEST_DATA();

            this.setState({
                menu:   testdata.menu,
                item:   testdata.menu_item
            });
        }
    }


    render() { 
        var menu = this.state.menu; 
        var item = this.state.item; 
        if(!item || !menu)  return <h1>REEE</h1>;
        console.log(item); 

        let modifier_list = item.modifier_list;

        
        return <main id="MenuItemView">
            <header>
                <img src={ item.imageurl } /> 
            </header>

            <h1 id="name">{ item.name }</h1>
            <p id="description">{ item.description }</p>

            { modifier_list && modifier_list.length > 0 && 
            <div className="ModifierListFrame">
                <h2 id="ModifierHeader">Customize</h2>
                <ModifierList data={ modifier_list } />
            </div> }

            <h2>Special Instructions</h2>
            { 
                item.custom_instructions_enabled && 
                <textarea id="SpecialInstructions" />
            }

        </main>;
    }




}