export const TEST_DATA = {
    "menu": {
      "location_id": 7845,
      "location_name": "Peppers",
      "brand_name": "Pepppers",
      "brand_id": 3,
      "user_account_id": "2519",
      "order_id": 66,
      "menus": [
        {
          "menu_id": 8159,
          "name": "Featured Items",
          "location_name": "Peppers",
          "availability_start": "07:30:00 AM",
          "availability_end": "10:00:00 PM",
          "category_list": [
            {
              "category_id": 5828,
              "name": "Featured",
              "description": "Popularly Picked Peppers!",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3956,
                  "name": "Fire-breathing Chicken Combo",
                  "price": 14.99,
                  "available": true,
                  "description": "Super hot chicken meal",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/1.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9208,
                      "name": "Side",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "alternate_maximum": 4,
                      "cost_additional": 1.99,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3960,
                          "name": "Fries",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9209,
                              "name": "Cook level",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3961,
                                  "name": "Normal",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3962,
                                  "name": "Extra Crispy",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "selected": false,
                          "item_id": 3963,
                          "name": "Brussel Sprouts",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3964,
                          "name": "Macaroni and Cheese",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3965,
                          "name": "Loaded Baked Potato",
                          "price": 0.99,
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9210,
                              "name": "Toppings",
                              "selection_minimum": 0,
                              "selection_maximum": 5,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3966,
                                  "name": "Butter",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3967,
                                  "name": "Sour Cream",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3968,
                                  "name": "Onion",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3969,
                                  "name": "Cheese",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3970,
                                  "name": "Bacon",
                                  "price": 0.99,
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "modifier_id": 9211,
                      "name": "Drink",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3971,
                          "name": "Coca-Cola",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3972,
                          "name": "Sprite",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3973,
                          "name": "Orange Fanta",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3974,
                          "name": "Tea",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9212,
                              "name": "Sweetness",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3975,
                                  "name": "Sweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3976,
                                  "name": "Half Sweet, Half Unsweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3977,
                                  "name": "Unsweet Tea",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3978,
                  "name": "Dragon's Breath Chili Combo",
                  "price": 11.99,
                  "available": true,
                  "description": "Hottest Chili around",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/2.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9208,
                      "name": "Side",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "alternate_maximum": 4,
                      "cost_additional": 1.99,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3960,
                          "name": "Fries",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9209,
                              "name": "Cook level",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3961,
                                  "name": "Normal",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3962,
                                  "name": "Extra Crispy",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "selected": false,
                          "item_id": 3963,
                          "name": "Brussel Sprouts",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3964,
                          "name": "Macaroni and Cheese",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3965,
                          "name": "Loaded Baked Potato",
                          "price": 0.99,
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9210,
                              "name": "Toppings",
                              "selection_minimum": 0,
                              "selection_maximum": 5,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3966,
                                  "name": "Butter",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3967,
                                  "name": "Sour Cream",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3968,
                                  "name": "Onion",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3969,
                                  "name": "Cheese",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3970,
                                  "name": "Bacon",
                                  "price": 0.99,
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "modifier_id": 9211,
                      "name": "Drink",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3971,
                          "name": "Coca-Cola",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3972,
                          "name": "Sprite",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3973,
                          "name": "Orange Fanta",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3974,
                          "name": "Tea",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9212,
                              "name": "Sweetness",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3975,
                                  "name": "Sweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3976,
                                  "name": "Half Sweet, Half Unsweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3977,
                                  "name": "Unsweet Tea",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3979,
                  "name": "Pick 2 tacos combo",
                  "price": 10.99,
                  "available": true,
                  "description": "Two tasty tacos",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/4.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9213,
                      "name": "Tacos",
                      "selection_minimum": 2,
                      "selection_maximum": 2,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3980,
                          "name": "Cajun Shimp taco",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3981,
                          "name": "Fire-breathing Chicken Taco",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3982,
                          "name": "Spicy fish tacos",
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9208,
                      "name": "Side",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "alternate_maximum": 4,
                      "cost_additional": 1.99,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3960,
                          "name": "Fries",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9209,
                              "name": "Cook level",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3961,
                                  "name": "Normal",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3962,
                                  "name": "Extra Crispy",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "selected": false,
                          "item_id": 3963,
                          "name": "Brussel Sprouts",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3964,
                          "name": "Macaroni and Cheese",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3965,
                          "name": "Loaded Baked Potato",
                          "price": 0.99,
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9210,
                              "name": "Toppings",
                              "selection_minimum": 0,
                              "selection_maximum": 5,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3966,
                                  "name": "Butter",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3967,
                                  "name": "Sour Cream",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3968,
                                  "name": "Onion",
                                  "available": true
                                },
                                {
                                  "selected": true,
                                  "item_id": 3969,
                                  "name": "Cheese",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3970,
                                  "name": "Bacon",
                                  "price": 0.99,
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "modifier_id": 9211,
                      "name": "Drink",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3971,
                          "name": "Coca-Cola",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3972,
                          "name": "Sprite",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3973,
                          "name": "Orange Fanta",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3974,
                          "name": "Tea",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9212,
                              "name": "Sweetness",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3975,
                                  "name": "Sweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3976,
                                  "name": "Half Sweet, Half Unsweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3977,
                                  "name": "Unsweet Tea",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3983,
                  "name": "Frozen Treat Medley",
                  "price": 7.99,
                  "available": true,
                  "description": "Cool down with our frozen treats",
                  "modifier_list": [
                    {
                      "modifier_id": 9214,
                      "name": "Frozen Treats",
                      "selection_minimum": 2,
                      "selection_maximum": 2,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3984,
                          "name": "Vanilla Ice Cream",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3985,
                          "name": "Fruit Popsicle",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9215,
                              "name": "flavor",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3986,
                                  "name": "Strawberry",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3987,
                                  "name": "Cherry",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3988,
                                  "name": "Orange",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3989,
                                  "name": "Lemon",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "selected": false,
                          "item_id": 3990,
                          "name": "Shaved Ice",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9216,
                              "name": "flavors",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "alternate_maximum": 3,
                              "cost_additional": 0.99,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3986,
                                  "name": "Strawberry",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3987,
                                  "name": "Cherry",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3988,
                                  "name": "Orange",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3989,
                                  "name": "Lemon",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ],
              "key": "5828"
            }
          ]
        },
        {
          "menu_id": 8160,
          "name": "Full Menu",
          "location_name": "Peppers",
          "availability_start": "09:30:00 AM",
          "availability_end": "09:00:00 PM",
          "category_list": [
            {
              "category_id": 5829,
              "name": "Appetizers",
              "description": "Hot Starters",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": false,
                  "item_id": 3991,
                  "name": "Chips and Salsa",
                  "price": 4.99,
                  "available": true,
                  "description": "Extra Hot Salsa"
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 3992,
                  "name": "Jalapeno Poppers",
                  "price": 7.99,
                  "available": true,
                  "description": "Deep fried Jalapenos with Cheese"
                }
              ],
              "key": "5829"
            },
            {
              "category_id": 5830,
              "name": "Entrees",
              "description": "Extra hot entrees",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3993,
                  "name": "Fire-Breathing Chicken",
                  "price": 10.99,
                  "available": true,
                  "description": "Hottest Chicken Around",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/1.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3994,
                  "name": "Dragon's Breath Chili",
                  "price": 8.99,
                  "available": true,
                  "description": "Tastes Like Lava",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/2.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 3995,
                  "name": "Dragon's Breath Chili",
                  "price": 8.99,
                  "available": true,
                  "description": "Tastes Like Lava",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/3.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9217,
                      "name": "Avocado amount",
                      "selection_minimum": 1,
                      "selection_maximum": 1
                    },
                    {
                      "modifier_id": 9218,
                      "name": "Other Toppings",
                      "selection_minimum": 0,
                      "selection_maximum": 4,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3969,
                          "name": "Cheese",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3999,
                          "name": "Corn",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 4000,
                          "name": "Black Beans",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 4001,
                          "name": "Dressing",
                          "available": true,
                          "description": "Spicy Vinaigrette Dressing"
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4002,
                  "name": "Spicy Garlic Ginger Wings",
                  "price": 10.99,
                  "available": true,
                  "description": "Our Popular Spicy Fusion Wings",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/5.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9219,
                      "name": "Count",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 4003,
                          "name": "6 Wings",
                          "price": -3,
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 4004,
                          "name": "10 Wings",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 4005,
                          "name": "20 Wings",
                          "price": 7,
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4006,
                  "name": "Flaming Steak Fajita",
                  "price": 13.99,
                  "available": true,
                  "description": "Steak, Peppers, Onions, and More!",
                  "imageurl": "https://smweb.s3.amazonaws.com/testimages/6.png",
                  "modifier_list": [
                    {
                      "modifier_id": 9220,
                      "name": "Tortilla Type",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 4007,
                          "name": "Corn Tortillas",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 4008,
                          "name": "Flour Tortillas",
                          "available": true
                        }
                      ]
                    },
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4009,
                  "name": "Blazing Pork Burrito",
                  "price": 8.99,
                  "available": false,
                  "description": "Pigs On Fire!",
                  "modifier_list": [
                    {
                      "modifier_id": 9207,
                      "name": "Spiciness level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": false,
                          "item_id": 3957,
                          "name": "Extra Hot",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3958,
                          "name": "Hot",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3959,
                          "name": "Mild",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                }
              ],
              "key": "5830"
            },
            {
              "category_id": 5831,
              "name": "Tacos",
              "description": "Famous Peppers Tacos",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4010,
                  "name": "Cajun Shimp taco",
                  "price": 6.99,
                  "available": true,
                  "description": "Our Most Popular Taco"
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4011,
                  "name": "Fire-breathing Chicken Taco",
                  "price": 6.99,
                  "available": true,
                  "description": "Our Famous Fire-Breathing Chicken in a Taco!"
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4012,
                  "name": "Spicy Fish Taco",
                  "price": 6.99,
                  "available": true,
                  "description": "An Extra Hot take on a classic"
                }
              ],
              "key": "5831"
            },
            {
              "category_id": 5832,
              "name": "Frozen Desserts",
              "description": "Something Cold After Something Hot",
              "availability_start": "03:30:00 PM",
              "availability_end": "09:00:00 PM",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4013,
                  "name": "Vanilla Ice Cream",
                  "price": 4.5,
                  "available": true,
                  "description": "Basic and Delicious"
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4014,
                  "name": "Popsicle",
                  "price": 4.5,
                  "available": true,
                  "description": "Our Large Homemade Popsicles",
                  "modifier_list": [
                    {
                      "modifier_id": 9215,
                      "name": "flavor",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3986,
                          "name": "Strawberry",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3987,
                          "name": "Cherry",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3988,
                          "name": "Orange",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3989,
                          "name": "Lemon",
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4015,
                  "name": "Shaved Ice",
                  "price": 3.99,
                  "available": true,
                  "description": "A Popular Treat. Try Mixing Flavors!",
                  "modifier_list": [
                    {
                      "modifier_id": 9216,
                      "name": "flavors",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "alternate_maximum": 3,
                      "cost_additional": 0.99,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3986,
                          "name": "Strawberry",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3987,
                          "name": "Cherry",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3988,
                          "name": "Orange",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3989,
                          "name": "Lemon",
                          "available": true
                        }
                      ]
                    }
                  ]
                }
              ],
              "key": "5832"
            },
            {
              "category_id": 5833,
              "name": "Sides and Drinks",
              "menu_item_list": [
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4016,
                  "name": "French Fries",
                  "price": 2.99,
                  "available": true,
                  "description": "French Fries",
                  "modifier_list": [
                    {
                      "modifier_id": 9209,
                      "name": "Cook level",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3961,
                          "name": "Normal",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3962,
                          "name": "Extra Crispy",
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4017,
                  "name": "Brussel Sprouts",
                  "price": 3.99,
                  "available": true,
                  "description": "Brussel Sprouts with Butter and Bacon"
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4018,
                  "name": "Macaroni and Cheese",
                  "price": 3.99,
                  "available": true,
                  "description": "Our Take Mac and Cheese with Homemade Pepper-Jack"
                },
                {
                  "custom_instructions_enabled": true,
                  "item_id": 4019,
                  "name": "Loaded Baked Potato",
                  "price": 3.99,
                  "available": true,
                  "description": "An Extra Large Baked Potato Stuffed with Toppings.",
                  "modifier_list": [
                    {
                      "modifier_id": 9210,
                      "name": "Toppings",
                      "selection_minimum": 0,
                      "selection_maximum": 5,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3966,
                          "name": "Butter",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3967,
                          "name": "Sour Cream",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3968,
                          "name": "Onion",
                          "available": true
                        },
                        {
                          "selected": true,
                          "item_id": 3969,
                          "name": "Cheese",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3970,
                          "name": "Bacon",
                          "price": 0.99,
                          "available": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "custom_instructions_enabled": false,
                  "item_id": 4020,
                  "name": "Drink",
                  "price": 1.99,
                  "available": true,
                  "description": "A 20 oz Fountain Drink",
                  "modifier_list": [
                    {
                      "modifier_id": 9221,
                      "name": "Drink Type",
                      "selection_minimum": 1,
                      "selection_maximum": 1,
                      "modifier_item_list": [
                        {
                          "selected": true,
                          "item_id": 3971,
                          "name": "Coca-Cola",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3972,
                          "name": "Sprite",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3973,
                          "name": "Orange Fanta",
                          "available": true
                        },
                        {
                          "selected": false,
                          "item_id": 3974,
                          "name": "Tea",
                          "available": true,
                          "modifier_list": [
                            {
                              "modifier_id": 9212,
                              "name": "Sweetness",
                              "selection_minimum": 1,
                              "selection_maximum": 1,
                              "modifier_item_list": [
                                {
                                  "selected": true,
                                  "item_id": 3975,
                                  "name": "Sweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3976,
                                  "name": "Half Sweet, Half Unsweet Tea",
                                  "available": true
                                },
                                {
                                  "selected": false,
                                  "item_id": 3977,
                                  "name": "Unsweet Tea",
                                  "available": true
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ],
              "key": "5833"
            }
          ]
        }
      ]
    },
    "menu_item": {
      "custom_instructions_enabled": true,
      "item_id": 3956,
      "name": "Fire-breathing Chicken Combo",
      "price": 14.99,
      "available": true,
      "description": "Super hot chicken meal",
      "imageurl": "https://smweb.s3.amazonaws.com/testimages/1.png",
      "modifier_list": [
        {
          "modifier_id": 9207,
          "name": "Spiciness level",
          "selection_minimum": 1,
          "selection_maximum": 1,
          "modifier_item_list": [
            {
              "selected": false,
              "item_id": 3957,
              "name": "Extra Hot",
              "available": true
            },
            {
              "selected": true,
              "item_id": 3958,
              "name": "Hot",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3959,
              "name": "Mild",
              "price": 0.99,
              "available": true
            }
          ]
        },
        {
          "modifier_id": 9208,
          "name": "Side",
          "selection_minimum": 1,
          "selection_maximum": 1,
          "alternate_maximum": 4,
          "cost_additional": 1.99,
          "modifier_item_list": [
            {
              "selected": true,
              "item_id": 3960,
              "name": "Fries",
              "available": true,
              "modifier_list": [
                {
                  "modifier_id": 9209,
                  "name": "Cook level",
                  "selection_minimum": 1,
                  "selection_maximum": 1,
                  "modifier_item_list": [
                    {
                      "selected": true,
                      "item_id": 3961,
                      "name": "Normal",
                      "available": true
                    },
                    {
                      "selected": false,
                      "item_id": 3962,
                      "name": "Extra Crispy",
                      "available": true
                    }
                  ]
                }
              ]
            },
            {
              "selected": false,
              "item_id": 3963,
              "name": "Brussel Sprouts",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3964,
              "name": "Macaroni and Cheese",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3965,
              "name": "Loaded Baked Potato",
              "price": 0.99,
              "available": true,
              "modifier_list": [
                {
                  "modifier_id": 9210,
                  "name": "Toppings",
                  "selection_minimum": 0,
                  "selection_maximum": 5,
                  "modifier_item_list": [
                    {
                      "selected": true,
                      "item_id": 3966,
                      "name": "Butter",
                      "available": true
                    },
                    {
                      "selected": true,
                      "item_id": 3967,
                      "name": "Sour Cream",
                      "available": true
                    },
                    {
                      "selected": true,
                      "item_id": 3968,
                      "name": "Onion",
                      "available": true
                    },
                    {
                      "selected": true,
                      "item_id": 3969,
                      "name": "Cheese",
                      "available": true
                    },
                    {
                      "selected": false,
                      "item_id": 3970,
                      "name": "Bacon",
                      "price": 0.99,
                      "available": true
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "modifier_id": 9211,
          "name": "Drink",
          "selection_minimum": 1,
          "selection_maximum": 1,
          "modifier_item_list": [
            {
              "selected": true,
              "item_id": 3971,
              "name": "Coca-Cola",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3972,
              "name": "Sprite",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3973,
              "name": "Orange Fanta",
              "available": true
            },
            {
              "selected": false,
              "item_id": 3974,
              "name": "Tea",
              "available": true,
              "modifier_list": [
                {
                  "modifier_id": 9212,
                  "name": "Sweetness",
                  "selection_minimum": 1,
                  "selection_maximum": 1,
                  "modifier_item_list": [
                    {
                      "selected": true,
                      "item_id": 3975,
                      "name": "Sweet Tea",
                      "available": true
                    },
                    {
                      "selected": false,
                      "item_id": 3976,
                      "name": "Half Sweet, Half Unsweet Tea",
                      "available": true
                    },
                    {
                      "selected": false,
                      "item_id": 3977,
                      "name": "Unsweet Tea",
                      "available": true
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  };