const axios = require('axios').default; 

import {TEST_DATA} from './ulink_test_data';

export class ULINK_API { 
    // static PFX =    'http://ulink.websuasion.net';
    // static KEY =    'c4ca42-38a0b923820dcc509a6f75849b';

    static elaborate(config) { 

        // let pfx         = ULINK_API.PFX; 
        // let endpoint    = config.endpoint; 

        // delete config.endpoint;
        // config.url      = `${pfx}/api/v1${endpoint}`

        // var headers = config.headers ?? {}; 
        // headers['api-key']          = ULINK_API.KEY; 
        // headers['bypass']           = 'true'; 
        // headers['content-type']     = 'application/json';

        // config.headers = headers; 
        // return config;


        /*
            This class exists as a wrapper around the ULink API
            For now, we access this through our locally-hosted mirror to avoid
                CORS protections. 
            Thus, we have to encapsulate the request with this parameter format
            We should be able to seamlessly bypass the whole thing when the time comes 
         */

        let endpoint = config.endpoint;
        delete config.endpoint; 

        config.url      = '/CORS-MIRROR';
        config.method   = 'post'; // POSTing all mirror requests, even the GET ones 
        
        let req_data = config.data;
        let req_prms = config.params;
        delete config.params;

        config.data = { 
            // This is the encapsulated params object sent to the server
            // `method` will be set by the accessors below 
            endpoint:       endpoint,
            params_body:    req_data ?? '',
            params_url:     req_prms,

        };

        return config; 
    }

    static get(config) { 
        config = ULINK_API.elaborate(config);
        // To be changed back to `config.method` when the mirror is removed 
        config.data.method = 'get'; 
        return axios(config); 
    }

    static post(config) { 
        config = ULINK_API.elaborate(config); 
        config.data.method = 'post'; // see above
        return axios(config);
    }






    //
    // Test case 
    //
    static TEST_DATA() { return TEST_DATA; }
}