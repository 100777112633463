import React from "react"
import PropTypes from "prop-types"


import { useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';


import { ULINK_API } from 'ulink_global';

import { LocationMenuSection, MenuSectionButton } from './LocationMenuSection.js'; 


export default class LocationMenu extends React.Component {


  state = { 
    menu:   null,
  }


  constructor(props) { 
    super(props);
    
    this.catMenu = React.createRef();
    this.refMain = React.createRef();
  }


  componentDidMount() { 

    // const { id } = this.props.match.params 
    // this.setState(() => ({ id }));

    var self = this; 
    // ULINK_API.post({
    //   endpoint: '/locations/menus',
    //   params: { 
    //     'location_id': 7845,
    //   },
    //   data: {
    //       "location_id": 7845,
    //       "user_account_id": "2519",
    //       "user_properties": {
    //           "name": "Joe",
    //           "phone": "1234323343"
    //       },
    //   }
    // }).then(function(response) { 

    //   var main = response.data.response[0]; 
    //   console.log(main); 


    //   self.setState(() => { menu: main });

    // }).catch(function(error){ 
    //   console.log('ERROR: ' + error); 
    // });



    self.setState(() => ({ menu: ULINK_API.TEST_DATA().menu }));



    window.addEventListener('scroll', this.handleScroll);
  }


  componentWillUnmount() { 
    window.removeEventListener('scroll', this.handleScroll); 
  }





  render () {
    var obj = this.state.menu;
    if(! obj) return (<p>Loading...</p>);


    var collection = obj.menus; 
    //TODO:  Filter out the above based on time of day 
    var sections = collection
      .flatMap(x => x['category_list'] )
      .map(x => { x.key = String(x.category_id);  return x }); // setting "key" here

    // console.log('SECTIONS:');
    // console.log(obj);
    console.log(sections[0].menu_item_list[0]);




    // Setting the CatMenu height here so that scroll offsets can be computed
    let cmHeight = 40; // px 
    let cmStyle = { 
      height:     cmHeight,
    }
    let scrollOffset = cmHeight + this.props.navRef.current.getBoundingClientRect().height;

    // Reinitializing these components as react-scroll links and elements
    let ScrollButton  = Scroll.ScrollLink(MenuSectionButton);
    let ScrollSection = Scroll.ScrollElement(LocationMenuSection); 


    return (   
      <main id="LocationMenu" ref={this.refMain}>

        <header>
          <div>
            <h1>{ obj.location_name }</h1>
            <p>1234 Avenue Street NE (2000 cubits away)</p>
          </div>
        </header>

        <nav ref={this.catMenu} className="MenuCategories" style={cmStyle} >
        { sections.map( x =>   
          (<ScrollButton data={x} key={x.key} 
              // react-scroll 
              to={x.key} smooth={true}   
              offset={-scrollOffset}
              spy={true} activeClass='spy-active'  
          />)   
        )}
        </nav>

        <div id="ScrollContainer" style={ {'--cat-height': cmHeight + 'px'} }>
        { sections.map( x =>   
          (<ScrollSection 
              data={x} parent={obj}
              // react-scroll 
              key={x.key} name={x.key} 
          />)   
        )}
        </div>

      </main>
    );
  }







  // ========
  // Scroll handling
  // --------
  // Sticking the categories menu on scroll 
  // ========


  // scrollY at which the Categories menu "sticks" to the top bar
  // null if that hasn't happened yet 
  stickHeight = null; 
  // when the user scrolls back past this Y height, it will unstick 

  // Listener, registered in component mounting methods above
  handleScroll = e =>{ 
    // console.log('scroll event');
    // return;

    let nav     = this.props.navRef.current;  // "Top banner"
    let cat     = this.catMenu.current;       // Categories menu 
    let main    = this.refMain.current;

    let win     = window.scrollY;
    let nav_hgt = nav.getBoundingClientRect().height;
    let cat_top = cat.getBoundingClientRect().top;

    // console.log(cat_top - nav_hgt);

    let css = 'stuck-cat'; 
    let sh = this.stickHeight;

    if(sh == null) {
      if(cat_top <= nav_hgt + 1) { 
        // The window has scrolled at or past the Categories menu; stick it 
        //  we give it a pixel of wiggle room to prevent flickering when using buttons
        main.classList.add(css); 
        this.stickHeight = win; 
      }
    }
    else { 
      if(win <= sh) { 
        // The window has scrolled past the original sticking position; unstick it
        main.classList.remove(css); 
        this.stickHeight = null; 
      }
    }
  }


  //
  // ===
  // 


}

